<template>
  <v-tooltip right content-class="tooltip-white" :nudge-right="nudgeRight" :disabled="expanded && !inactive">
    <template v-slot:activator="data">
      <slot v-bind="data"></slot>
    </template>
    <span>{{ $t(text) }}</span>
  </v-tooltip>
</template>

<script>
import { i18n } from '@/plugins/i18n';

export default {
  name: 'SidebarTooltip',
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    hint: {
      type: String,
      required: true,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    nudgeRight: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    text() {
      const forRegistered = 'sidebar.forRegistered';
      switch (true) {
        case this.expanded && this.inactive:
          return forRegistered;
        case !this.expanded && this.inactive:
          return `${this.hint}. ${i18n.t(forRegistered)}`;
        default:
          return this.hint;
      }
    },
  },
};
</script>
