<template>
  <action-view v-on="$listeners" :class="buttonClass">
    <img class="desktop-icon" :src="require('@/assets/icons/arrow-white.svg')" width="16" height="16" alt="" />
    <img class="mobile-icon" :src="require('@/assets/icons/hamburger-menu.svg')" width="24" height="24" alt="" />
  </action-view>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionView from '@/elements/atoms/ActionView';

export default {
  name: 'ExpandButton',
  components: { ActionView },
  computed: {
    ...mapGetters('UserModule', { isExpanded: 'sidebarExpanded' }),
    buttonClass() {
      return {
        'expand-button': true,
        'expand-button-collapse': this.isExpanded,
        'hide-hamburger': this.$route.name !== 'natal-chart',
      };
    },
  },
};
</script>

<style scoped lang="sass">
.expand-button
  position: absolute
  left: base-unit(10)
  top: base-unit(10)
  border-radius: 50%
  padding: base-unit(6)
  background: #EDF4F7
  +large-devices
    left: calc(100% - #{base-unit(12)})
    top: base-unit(30)
    background: #8BBFD0

  &-collapse
    background: transparent
    .mobile-icon
      display: none
    +large-devices
      background: #8BBFD0
      transform: rotate(180deg)

  &:hover
    opacity: 1

.desktop-icon
  transition: 0.3s linear
  display: none
  +large-devices
    display: block

.mobile-icon
  display: block
  +large-devices
    display: none

.hide-hamburger
  display: none
  +large-devices
    display: block
</style>
