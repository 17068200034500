<template>
  <router-link to="/" class="sidebar-logo">
    <img width="30" :src="require('@/assets/logo/LogoCollapsed.svg')" alt="logo" />
    <img v-if="isExpanded" class="logo-text" width="100" :src="require('@/assets/logo/LogoTextEu.svg')" alt="logo" />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarLogo',
  computed: {
    ...mapGetters('UserModule', { isExpanded: 'sidebarExpanded' }),
  },
};
</script>

<style scoped lang="sass">
.sidebar-logo
  display: flex
  align-items: center
  margin-bottom: base-unit(20)

.logo-text
  margin-left: base-unit(12)
  transition: 0.3s
</style>
