<template>
  <sidebar-tooltip
    :hint="label"
    :expanded="isExpanded || isInDropdown"
    :inactive="inactive"
    :nudge-right="nudgeRight"
    v-slot="{ attrs, on }"
  >
    <component
      :is="actionComponent"
      :href="href"
      :class="['sidebar-action', { inactive, collapsible, light: !icon, expanded, current }]"
      :target="target"
      v-on="{ ...$listeners, ...on }"
      v-bind="attrs"
    >
      <img v-if="icon" :src="icon" width="24" height="24" :alt="label" />
      <span v-else class="sidebar-action__icon-stub" />
      <span v-if="isExpanded || isInDropdown" class="sidebar-action__text">
        {{ label }}
      </span>
      <img
        alt=""
        v-if="collapsible"
        :src="require('@/assets/icons/arrow-white.svg')"
        width="16"
        height="16"
        :class="['sidebar-action__collapse-icon', { expanded, rotate, 'sidebar-collapsed': !isExpanded }]"
      />
    </component>
  </sidebar-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionView from '@/elements/atoms/ActionView';
import SidebarTooltip from '@/elements/components/sidebar/SidebarTooltip';

export default {
  name: 'SidebarAction',
  components: {
    SidebarTooltip,
    ActionView,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    icon: {
      type: String,
      default: '',
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: Boolean,
      default: false,
    },
    nudgeRight: {
      type: Number,
      default: 0,
    },
    isInDropdown: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('UserModule', { isExpanded: 'sidebarExpanded' }),
    actionComponent() {
      return this.href ? 'a' : ActionView;
    },
  },
};
</script>

<style scoped lang="sass">
.sidebar-action__wrapper
  width: fit-content

.sidebar-action
  position: relative
  display: flex
  align-items: center
  width: 100%
  justify-content: center
  padding: base-unit(5) 0
  +base-font-size
  font-weight: 400
  color: white
  text-decoration: none
  img
    width: base-unit(16)
    height: base-unit(16)
    &[alt='Support']
      width: base-unit(20)
      height: base-unit(20)
    &[alt='Help center']
      width: base-unit(20)
      height: base-unit(20)

  &.collapsible
    width: 100%

  &.light
    margin-bottom: 0

  &.inactive
    color: #8BBFD0
    img
      opacity: 0.4
    &:hover
      opacity: 1

  &:hover
    opacity: 0.8

  &__text
    margin-left: base-unit(8)
    transition: 0.3s linear
    color: inherit

  &__collapse-icon
    position: absolute
    left: calc(100% - #{base-unit(10)})
    transition: 0.3s linear
    &.expanded
      transform: rotate(90deg)
    &.rotate
      transform: rotate(180deg)
    &.sidebar-collapsed
      left: base-unit(30)

  &__icon-stub
    min-width: base-unit(16)
    height: base-unit(16)
  &.current
    background-color: #509EB9
    border-radius: base-unit(4)
</style>
