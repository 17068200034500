<template>
  <div class="base-layout" :class="{ 'base-layout--blurred': clickedModal }">
    <sidebar :class="{ fixedPositionOnExtraLargeScreens }" />
    <div class="base-layout__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Sidebar from '@/elements/components/sidebar';

export default {
  name: 'BaseLayout',
  components: {
    Sidebar,
  },
  methods: {
    ...mapActions('UserModule', ['setSidebarExpansion']),
  },
  mounted() {
    const screenWidth = window.screen.width;
    if (this.isMobileScreen || (screenWidth >= 1280 && screenWidth <= 1440)) {
      this.setSidebarExpansion(false);
    }
  },
  computed: {
    ...mapGetters('UserModule', ['sidebarExpanded', 'clickedModal']),
    fixedPositionOnExtraLargeScreens() {
      // make sidebar to overlap the map
      return this.$route.name === 'natal-chart';
    },
  },
};
</script>

<style scoped lang="sass">
.base-layout
  display: flex
  flex-direction: row
  width: 100%
  position: relative
  overflow-y: hidden
  max-width: 100vw
  min-height: 100vh
  +large-devices
    max-height: 100vh

  .fixedPositionOnExtraLargeScreens
    +extra-large-devices
      position: fixed
    +high-resolution-devices
      position: relative

  &__content
    display: flex
    flex: 1 0 0
    overflow-y: auto
    max-width: 100vw

  &--blurred
    filter: blur(4px)
</style>
