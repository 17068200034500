<template>
  <a target="_blank" :href="link" class="banner_item">
    <div class="banner_item-inner">
      <img class="banner_icon" :src="icon" alt="icon banner" />
      <span class="banner_text">{{ text }}</span>
    </div>
    <div class="banner_arrow-wrapper">
      <img class="banner_arrow" :src="require(`@/assets/icons/arrow-right-${arrowType}.svg`)" alt="" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'BannerIo',
  props: {
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    arrowType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
.banner_item
  position: relative
  display: flex
  align-items: center
  border-radius: 10px
  text-decoration: none
  padding: base-unit(10) base-unit(24) base-unit(10) base-unit(16)
  +large-devices
    padding: base-unit(10) base-unit(20) base-unit(16) base-unit(16)
  &:nth-child(1)
    background: linear-gradient(90deg, #F882C7 0%, #F2C94C 100%)
  &:nth-child(2)
    background: linear-gradient(90deg, #47A1EE 0%, #EDCE80 100%)
  &:nth-child(3)
    background: linear-gradient(90deg, #8295EB 0%, #F776B3 100%)
  &-inner
    display: flex
    align-items: center
    column-gap: base-unit(11)
    +large-devices
      align-items: start
      flex-direction: column
      row-gap: base-unit(7)
.banner_arrow-wrapper
  position: absolute
  right: base-unit(-12.5)
  top: 50%
  transform: translateY(-50%)
  border-radius: 50%
  background: #FFFFFF
  box-shadow: 0px 8px 16px rgba(22, 126, 162, 0.15)
  display: flex
  align-items: center
  justify-content: center
  padding: base-unit(10)
.banner_text
  font-weight: 500
  font-size: base-unit(16)
  line-height: 150%
  color: #FFFFFF
  text-decoration: none
  max-width: base-unit(150)
</style>
