<template>
  <div class="sidebar-container" :class="{ expanded: sidebarExpanded }">
    <expand-button @click="toggleSidebar" />
    <div ref="navContent" class="sidebar" :class="sidebarClass">
      <img class="sidebar-close-btn" src="@/assets/icons/close.svg" alt="close" @click="toggleSidebar" />
      <sidebar-logo />
      <div class="sidebar__actions">
        <sidebar-action
          :current="this.$route.name === 'chart-settings'"
          @click="checkLogin($event, 'chart-settings')"
          :icon="require('@/assets/icons/chart-settings.svg')"
          :label="$t('sidebar.settings')"
        />
        <sidebar-action
          :current="this.$route.name === 'chart-history'"
          v-if="isHistoryEnabled"
          @click="checkLogin($event, 'chart-history')"
          :icon="require('@/assets/icons/star.svg')"
          :label="$t('sidebar.base')"
        />
        <sidebar-action
          :current="this.$route.name === 'tariffs'"
          @click="redirect($event, 'tariffs')"
          :icon="require('@/assets/icons/tariffs_icon-sidebar.svg')"
          :label="$t('sidebar.plans')"
        />
      </div>
      <!--      <banner-container v-if="isDesktopScreen" v-show="sidebarExpanded" />-->
      <!--      <div class="banner-container" v-show="sidebarExpanded">
        <banner-io
          v-for="banner in banners"
          :key="'banner' + banner.id"
          :link="banner.link"
          :text="banner.text"
          :arrow-type="banner.arrowType"
          :icon="banner.icon"
        >
        </banner-io>
      </div>-->
      <div class="spacer"></div>
      <!--      <div class="sidebar__actions">
        <template v-if="sidebarExpanded">
          <sidebar-action
            :icon="require('@/assets/icons/support.svg')"
            :label="$t('sidebar.support')"
            :collapsible="true"
            :expanded="expandSupport"
            @click="toggleSupport"
          />
          <collapse-transition>
            <div class="sidebar__links" v-show="expandSupport">
              <sidebar-action key="1" :label="$t('sidebar.center')" href="https://astronova.io/help" target="_blank" />
              <sidebar-action key="2" :label="$t('sidebar.chat')" @click="openChat" />
            </div>
          </collapse-transition>
        </template>
        <sidebar-dropdown v-else>
          <template v-slot:trigger>
            <sidebar-action
              :icon="require('@/assets/icons/support.svg')"
              :label="$t('sidebar.support')"
              :collapsible="true"
              :rotate="expandSupport"
              :nudge-right="30"
              @click="toggleSupport"
            />
          </template>
          <template v-slot:content>
            <div>
              <sidebar-action
                key="1"
                :label="$t('sidebar.center')"
                href="https://astronova.io/help"
                target="_blank"
                isInDropdown
              />
              <sidebar-action key="2" :label="$t('sidebar.chat')" isInDropdown @click="openChat" />
            </div>
          </template>
        </sidebar-dropdown>
        &lt;!&ndash;        <sidebar-action
          href="https://astronova.ru/obuchenie/"
          target="_blank"
          :icon="require('@/assets/icons/study.svg')"
          :label="$t('sidebar.learn')"
        />&ndash;&gt;
        &lt;!&ndash;        <sidebar-action
          href="https://astronova.ru/blog/"
          :icon="require('@/assets/icons/blog.svg')"
          :label="$t('sidebar.blog')"
        />&ndash;&gt;
      </div>-->
      <sidebar-action
        href="https://astronova.io/help"
        :icon="require('@/assets/icons/support.svg')"
        :label="$t('sidebar.support')"
      />
      <div class="function_nav">
        <sidebar-social-links />
        <!--        <select class="langCont" v-model="$i18n.locale" @change="changeLocale()">
                  <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                    {{ lang }}
                  </option>
                </select>-->
        <div class="langCont">
          <button @click="changeLocale(lang)" v-for="(lang, i) in langs" :key="`Lang${i}`">
            {{ lang }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

import { ExpandButton } from './buttons';
import SidebarAction from './SidebarAction';
import SidebarLogo from './SidebarLogo';
import SidebarSocialLinks from './SidebarSocialLinks';
/*import SidebarDropdown from './SidebarDropdown';*/
import { isProduction } from '@/utils/common';
/*import { CollapseTransition } from '@/elements/components/transitions';*/
import { i18n } from '@/plugins/i18n';

const isHistoryEnabled = process.env.VUE_APP_ENABLE_HISTORY === 'true';
const isTariffsEnabled = process.env.VUE_APP_ENABLE_TARIFFS === 'true';

import axios from 'axios';
import BannerIo from '../banners/BannerIo';

export default {
  name: 'Sidebar',
  components: {
    BannerIo,
    SidebarSocialLinks,
    SidebarLogo,
    ExpandButton,
    SidebarAction,
    /*    CollapseTransition,
    SidebarDropdown,*/
  },
  data() {
    return {
      langs: ['en', 'de'],
      isProduction,
      listenersAreSet: false,
      expandSupport: true,
      isHistoryEnabled,
      isTariffsEnabled,
      banners: [
        {
          id: 1,
          arrowType: 'orange',
          text: 'Basic astrology online course',
          link: 'https://www.irinachukreeva.com/ka?utm_source=site&utm_medium=astropr&utm_campaign=banner',
          icon: require('@/assets/icons/banners-io/school.svg'),
        },
        {
          id: 2,
          arrowType: 'blue',
          text: 'Sun boosting actions',
          link: 'https://www.irinachukreeva.com/sun?utm_source=site&utm_medium=astropr&utm_campaign=banner',
          icon: require('@/assets/icons/banners-io/graph.svg'),
        },
        {
          id: 3,
          arrowType: 'purple',
          text: 'Personal session with astrologer',
          // eslint-disable-next-line max-len
          link: 'https://www.irinachukreeva.com/3d_core_of_personality?utm_source=site&utm_medium=astropr&utm_campaign=banner',
          icon: require('@/assets/icons/banners-io/smile.svg'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('UserModule', ['isLoggedIn', 'currentUser', 'sidebarExpanded']),
    sidebarClass() {
      return {
        'sidebar-collapsed': !this.sidebarExpanded,
        'sidebar-expanded': this.sidebarExpanded,
      };
    },
    profileAction() {
      return this.isLoggedIn ? this.profileActions.logOut : this.profileActions.signIn;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    if (this.listenersAreSet) {
      this.removeListener();
    }
  },
  methods: {
    ...mapActions('UserModule', ['logOut', 'setSidebarExpansion', 'clickModal']),
    redirect(event, name) {
      if (this.isMobileScreen) {
        this.toggleSidebar(event);
      }
      if (this.$route.name !== name) {
        const content = document.querySelector('.base-layout__content');

        if (content) {
          content.scrollTo(0, 0);
        }
        this.$router.push({ name });
      }
    },
    removeListener() {
      window.removeEventListener('click', this.handleClosing);
      window.removeEventListener('focusin', this.handleClosing);
      this.listenersAreSet = false;
    },
    onResize: debounce(function () {
      const { $mq } = this;

      if ($mq === 'lg' || $mq === 'exlg') {
        this.setSidebarExpansion(false);
      }

      const largeScreen = $mq === 'sm' || $mq === 'md' || $mq === 'lg';

      if (largeScreen && !this.listenersAreSet) {
        window.addEventListener('click', this.handleClosing);
        window.addEventListener('focusin', this.handleClosing);
        this.listenersAreSet = true;
      } else {
        if ($mq === 'hr' && this.listenersAreSet) {
          this.removeListener();
        }
      }
    }, 200),
    async toggleSidebar(event) {
      event.stopPropagation();
      await this.setSidebarExpansion(!this.sidebarExpanded);
      if (!this.sidebarExpanded) {
        this.expandSupport = false;
      }
    },
    handleClosing(event) {
      if (!this.$refs.navContent.contains(event.target)) {
        this.setSidebarExpansion(false);
      }
    },
    toggleSupport() {
      this.expandSupport = !this.expandSupport;
    },
    openChat() {
      // eslint-disable-next-line no-undef
      carrotquest.open();
    },

    changeLocale(lang) {
      /*i18n.locale = currentLang;*/
      this.$i18n.locale = lang;
      const getHTMLTag = document.documentElement;
      getHTMLTag.setAttribute('lang', i18n.locale);
      sessionStorage.setItem('locale', this.$i18n.locale);
      axios.defaults.headers.common['Accept-Language'] = lang;
    },

    checkLogin(e, action) {
      if (this.isLoggedIn) {
        this.redirect(e, action);
      } else {
        this.clickModal(true);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.sidebar-container
  position: fixed
  display: grid // don't know why, but it works
  opacity: 1
  z-index: 100
  max-height: 100vh
  align-self: stretch
  height: auto
  min-height: 100%
  background-color: blue-color('primary')
  width: 0
  transition: width 0.3s ease-out
  &.expanded
    width: $sidebar-width-expanded-mobile
    +large-devices
      width: $sidebar-width-expanded-desktop
  +large-devices
    position: relative
    width: $sidebar-width-collapsed
  +high-resolution-devices
    position: relative

.sidebar
  display: flex
  flex-direction: column
  flex-shrink: 0
  box-sizing: border-box
  height: 100%
  max-height: 100vh
  &-collapsed::v-deep
    display: none

    +large-devices
      display: flex
      height: 100%
      padding: base-unit(30) 0
      align-items: center
    .function_nav
      flex-direction: column
      height: auto
      row-gap: base-unit(25)
      .langCont
        flex-direction: column
        row-gap: base-unit(10)
    .sidebar-action
      padding: base-unit(11) base-unit(20)

  &-expanded::v-deep
    width: inherit
    padding: base-unit(30) base-unit(20)
    +large-devices
      padding: base-unit(30) base-unit(30)
    .sidebar-action
      justify-content: initial
      margin-bottom: base-unit(20)
      padding: 0
    .current
      padding: base-unit(5) 0
      background: none
  .sidebar-close-btn
    position: absolute
    right: base-unit(15)
    top: base-unit(15)
    width: base-unit(16)
    height: base-unit(16)
    +large-devices
      display: none

  &__actions
    display: flex
    flex-direction: column

  &__banners
    margin-bottom: base-unit(20)

.spacer
  flex: 1 1 0

.sidebar__links
  margin-bottom: base-unit(10)

.function_nav
  display: flex
  max-width: base-unit(70)
  justify-content: space-between
  align-items: center
  .langCont
    width: 100%
    display: flex
    justify-content: space-between
    color: #fff
    column-gap: base-unit(10)
    button
      text-transform: uppercase
.banner-container
  row-gap: base-unit(20)
  display: flex
  flex-direction: column
  height: 100%
</style>
