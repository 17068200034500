<template>
  <div :class="['social-links', { expanded: isExpanded }]">
    <sidebar-tooltip
      v-for="link in links"
      :key="link.name"
      :hint="link.name"
      :expanded="isExpanded"
      v-slot="{ attrs, on }"
    >
      <a class="social-links__item" target="_blank" :class="linkClass" :href="link.href" v-on="on" v-bind="attrs">
        <img class="social-links__item-icon" :src="link.icon" :width="iconSize" :height="iconSize" :alt="link.name" />
      </a>
    </sidebar-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SidebarTooltip from './SidebarTooltip';

export default {
  name: 'SidebarSocialLinks',
  components: {
    SidebarTooltip,
  },
  data() {
    return {
      links: [
        {
          name: 'Astronova',
          href: 'https://app.astronova.ru',
          // eslint-disable-next-line no-undef
          icon: require('@/assets/icons/globe_v2.svg'),
        },
        /*{
          name: 'Telegram',
          href: 'https://t.me/astro_nova',
          // eslint-disable-next-line no-undef
          icon: require('@/assets/icons/telegram.svg'),
        },*/
      ],
    };
  },
  computed: {
    ...mapGetters('UserModule', { isExpanded: 'sidebarExpanded' }),
    linkClass() {
      return {
        'social-links__item-collapsed': !this.isExpanded,
        'social-links__item-expanded': this.isExpanded,
      };
    },
    iconSize() {
      return this.isExpanded ? 30 : 24;
    },
  },
};
</script>

<style scoped lang="sass">
.social-links
  display: flex
  margin-top: base-unit(5)
  flex-direction: column
  align-items: center

  &.expanded
    flex-direction: row

  &__item
    outline: none
    text-underline: none
    transition: 0.3s linear

    &:hover
      opacity: 0.8

    &:last-child
      margin: 0

    &-expanded
      margin: 0 base-unit(10) 0 0

    &-icon
      transition: 0.3s linear
      width: base-unit(16)
      height: base-unit(16)
</style>
